import React, { useEffect, useRef } from 'react';
import { Mobile, PC } from './ReactiveLayout';
import M from '../images/remodeling/remodeling.jpg'
import Before1 from '../images/remodelres/before1.jpg'
import Before2 from '../images/remodelres/before2.jpg'
import After1 from '../images/remodelres/after1.jpg'
import After2 from '../images/remodelres/after2.jpg'
import Grid11 from '../images/remodelres/grid1-1.jpg'
import Grid12 from '../images/remodelres/grid1-2.jpg'
import Grid21 from '../images/remodelres/grid2-1.jpg'
import Grid22 from '../images/remodelres/grid2-2.jpg'
import { Card, CardContent } from '@mui/material';
import CircleArrowBtn from '../components/MainHomePg/Main/CircleArrowBtn';
import { useTranslation } from 'react-i18next';
function RemodelRes(props) {
    const {t} = useTranslation();
         // ref를 생성하여 purchase-txt 구간을 참조
         const purchaseTxtRef = useRef(null);

    // 페이지 로드 시 스크롤을 맨 위로 이동
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

         // 버튼 클릭 시 스크롤 이벤트 처리
         const handleScrollToSection = () => {
             if (purchaseTxtRef.current) {
                 purchaseTxtRef.current.scrollIntoView({ behavior: 'smooth' });
             }
         };
    const SampleHouseCardSx={
        width:"335px",
        minWidth:"335px",
        display:"flex",
        flexDirection:"column",
        margin:0,
        backgroundColor:"#F5E9C7"
    }
    const BeforeCardSx={
   borderRadius:"30px",
   height:"500px",
   width:"260px"
    }
    const LeftAlignCardSx={
        borderRadius:"30px",
        height:"420px",
        width:"500px"
         }
       const LeftAlignCardMbSx={
            borderRadius:"30px",
            height:"420px",
            width:"100%"
             }
         const RightAlignCardSx={
            borderRadius:"30px",
            height:"600px",
            width:"700px"
             }
    const AfterCardSx={
        borderRadius:"30px",
        height:"500px",
        width:"100%"
         }
    const SampleHouseCardContentSx={
        padding:0
    }
    return (
        <div>
            <PC>
            <div className="remodeling-area">
                {/* 맨위에 이미지랑 글자 */}
<div className="main-banner-txts">
        <div className="banner-txt-top">
       <div className="column"
       style={{
        display:"flex",
        flexDirection:"column"
       }}
       >
       <span>
      {t("remodelRes.bannerTop1")}
        </span>
        <span>
            <strong
            style={{
                fontSize:"55px"
            }}
            >
            {t("remodelRes.bannerTopStrong")}
            </strong>
           
        </span>
       </div>
        </div>
        <div className="banner-txt-bottom">
        {t("remodelRes.bannerBottom")}
        </div>
   <div className="more">
    <CircleArrowBtn onClick={handleScrollToSection}/>
   </div>
    </div>
    <div className="remodeling-img">
    <img src={M}
    style={{
        width:"800px"
    }}
    />

    </div>
</div>
<div className="staging-txt"
ref={purchaseTxtRef}
>
    <strong
    style={{
        fontSize:"40px",
        color:"black",
        whiteSpace:"nowrap"
    }}
    >{t("remodelRes.title")}</strong>
    <div className="staging-txt-bottom"
    style={{
        textAlign:"center"
    }}
    >
  {t("remodelRes.content1")}
    </div>

</div>
{/* 왼쪽 정렬 비포1 */}
<div className="left-align-area">
<Card
        sx={LeftAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={Before1}/>
            </CardContent>
        </Card>
        <div className="left-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent1")}</span>
        </div>
</div>
{/* 오른쪽 정렬 애프터1 */}
<div className="left-align-area">
<div className="right-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold",
                display:"flex",
                flexDirection:"column"
            }}
            >
                <strong>
                {t("imgGrid.after")}
             </strong></p>
                <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            > {t("imgGrid.afterContent1")}</span>
            
                
            
           
        </div>
<Card
        sx={RightAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='right-align-img'
                src={After1}/>
            </CardContent>
        </Card>
        
</div>
{/* 왼쪽 정렬 비포2 */}
<div className="left-align-area">
<Card
        sx={LeftAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={Before2}/>
            </CardContent>
        </Card>
        <div className="left-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent1")}</span>
        </div>
</div>
{/* 오른쪽 정렬 애프터2 */}
<div className="left-align-area">
<div className="right-align-txt">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold",
                display:"flex",
                flexDirection:"column"
            }}
            >
                <strong>
                {t("imgGrid.after")}</strong></p>
                <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent2")}</span>
            
                
            
           
        </div>
<Card
        sx={RightAlignCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='right-align-img'
                src={After2}/>
            </CardContent>
        </Card>
        
</div>
{/* 이미지 그리드 */}
<div className="remodeling-before-after"
style={{
    
    width: "calc(100% - 300px - 300px)",
    marginLeft: "300px",
    marginRight: "300px",
}}
>
    {/* 첫번째줄 사진 */}
    <div className="remodeling-row">

        {/* 전 */}
    <div className="before-area">
        <Card
        sx={BeforeCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='remodeling-before-img'
                style={{
                    height:"500px"
                    // transform:`translateX(100px)`
                }}
                src={Grid11}/>
            </CardContent>
        </Card>
        <span>{t("imgGrid.before")}</span>
    </div>
    {/* 후 */}
    <div className="after-area">
        <Card
        sx={AfterCardSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='remodeling-after-img'
              
                src={Grid12}/>
            </CardContent>
        </Card>
        <span>{t("imgGrid.after")}</span>
    </div>
    </div>
    {/* 두번째줄 사진 */}
    <div className="remodeling-row">

{/* 전 */}
<div className="before-area">
<Card
sx={BeforeCardSx}
>
    <CardContent
    sx={SampleHouseCardContentSx}
    >
        <img 
        className='remodeling-before-img'
        style={{
            height:"500px"
            // transform:`translateX(100px)`
        }}
        src={Grid21}/>
    </CardContent>
</Card>
<span>{t("imgGrid.before")}</span>
</div>
{/* 후 */}
<div className="after-area">
<Card
sx={AfterCardSx}
>
    <CardContent
    sx={SampleHouseCardContentSx}
    >
        <img 
        className='remodeling-after-img'
        style={{
            transform:`translateY(-160px)`
       }}
        src={Grid22}/>
    </CardContent>
</Card>
<span>{t("imgGrid.after")}</span>
</div>
</div></div>
            </PC>
            <Mobile>
            <div className="remodeling-area-mb">
                {/* 맨위에 이미지랑 글자 */}
                <div className="remodeling-img-mb">
    <img src={M}
    style={{
        transform:"translateX(-120px)"
        // width:"800px"
    }}
    />

    </div>
<div className="main-banner-txts-mb">
        <div className="banner-txt-top-mb">
   
       <span>
       {t("remodelRes.bannerTop1")}
        </span>
        <span>
            <strong
            style={{
                fontSize:"36px",
            }}
            >
             {t("remodelRes.bannerTopStrong")}
            </strong>
      
        </span>
        </div>
        <div className="banner-txt-bottom-mb">
        {t("remodelRes.bannerBottom")}
        </div>
   <div className="more">
    <CircleArrowBtn onClick={handleScrollToSection}/>
   </div>
    </div>
   
</div>
<div className="staging-txt-mb"
ref={purchaseTxtRef}
>
    <strong
    style={{
        fontSize:"40px",
        color:"black",
    }}
    >{t("remodelRes.title")}</strong>
    <div className="staging-txt-bottom-mb"
    style={{
        textAlign:"center"
    }}
    >
  {t("remodelRes.content1")}
    </div>

</div>
{/* 왼쪽 정렬 비포1 */}
<div className="photos-grid-mb">
<Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={Before1}/>
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent1")}</span>
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={After1}/>
            </CardContent>
        </Card>

        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.after")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent1")}</span>
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={Before2}
                style={{
                    transform:"translateX(-200px)"
                }}
                />
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.before")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.beforeContent1")}</span>
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={After2}
                style={{
                    height:"420px",
                      transform:"translateX(-100px)"
                }}
                />
            </CardContent>
        </Card>

        <div className="left-align-txt-mb">
            <p
            style={{
                fontSize:"30px",
                fontWeight:"bold"
            }}
            >
                <strong>{t("imgGrid.after")}</strong>
                
            </p>
            <span
              style={{
                fontSize:"18px",
                color:"#888888"
            }}
            >{t("imgGrid.afterContent1")}</span>
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={Grid11}
                style={{
                    // transform:"translateX(-200px)"
                }}
                />
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
        
                <strong
                style={{
                    fontSize:"30px"
                }}
                >{t("imgGrid.before")}</strong>
                
       
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={Grid12}
                style={{
                    height:"420px",
                    //   transform:"translateX(-100px)"
                }}
                />
            </CardContent>
        </Card>

        <div className="left-align-txt-mb">
           
                <strong
                style={{
                    fontSize:"30px"
                }}
                >{t("imgGrid.after")}</strong>
                
            
               </div>
               <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={Grid21}
                style={{
                    // transform:"translateX(-200px)"
                }}
                />
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
        
                <strong
                style={{
                    fontSize:"30px"
                }}
                >{t("imgGrid.before")}</strong>
                
       
        </div>
        <Card
        sx={LeftAlignCardMbSx}
        >
            <CardContent
            sx={SampleHouseCardContentSx}
            >
                <img 
                className='left-align-img'
                src={Grid22}
                style={{
                    // transform:"translateX(-200px)"
                }}
                />
            </CardContent>
        </Card>
        <div className="left-align-txt-mb">
        
                <strong
                style={{
                    fontSize:"30px"
                }}
                >{t("imgGrid.after")}</strong>
                
       
        </div>
</div>

            </Mobile>
        </div>
    );
}

export default RemodelRes;