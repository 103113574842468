import React, { useRef } from 'react';
import { Mobile, PC } from './ReactiveLayout';
import CircleArrowBtn from '../components/MainHomePg/Main/CircleArrowBtn';
import M from '../images/purchase/purchase-main.jpg';
import './Purchase.css';
import { useTranslation } from 'react-i18next';

function Purchase(props) {
    const {t} = useTranslation();
    // ref를 생성하여 purchase-txt 구간을 참조
    const purchaseTxtRef = useRef(null);

    // 버튼 클릭 시 스크롤 이벤트 처리
    const handleScrollToSection = () => {
        if (purchaseTxtRef.current) {
            purchaseTxtRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div>
            <PC>
                <div className="remodeling-area">
                    {/* 맨위에 이미지랑 글자 */}
                    <div className="main-banner-txts">
                        <div className="banner-txt-top">
                            <div className="column"
                                style={{
                                    display: "flex",
                                    flexDirection: "column"
                                }}>
                                <span>
                                    {t("main.bannerTop1")}
                                    <strong style={{ fontSize: "55px" }}>{t("main.bannerTopStrong")}</strong>
                                </span>
                                <span>{t("main.bannerTop2")}</span>
                            </div>
                        </div>
                        <div className="banner-txt-bottom">{t("purchase.bannerBottom")}</div>
                        <div className="more">
                            <CircleArrowBtn onClick={handleScrollToSection} />
                        </div>
                    </div>
                    <div className="remodeling-img">
                        <img src={M}
                            style={{
                                transform: "translateY(-200px)",
                                width: "800px"
                            }} />
                    </div>
                </div>
                <div className="purchase-txt" ref={purchaseTxtRef}>
                    <strong style={{ fontSize: "40px", color: "black", whiteSpace: "nowrap" }}>
                       {t("purchase.purchaseTitle")}
                    </strong>
                    <div className="purchase-txt-bottom">
                        <p>{t("purchase.content1")}</p>
                        <p>{t("purchase.content2")}</p>
                        <p>
                            <strong style={{ fontSize: "26px" }}>{t("purchase.content3Strong")}</strong> 
                            {t("purchase.content3")}
                        </p>
                        <p>
                            {t("purchase.content4")}
                        </p>
                       {t("purchase.content5")}
                        </div>
                </div>
            </PC>
            <Mobile>
                <div className="remodeling-area-mb">
                    {/* 맨위에 이미지랑 글자 */}
                    <div className="remodeling-img-mb">
                        <img src={M} style={{ transform: "translateX(-130px)" }} />
                    </div>
                    <div className="main-banner-txts-mb">
                        <div className="banner-txt-top-mb">
                            <span> {t("main.bannerTop1")} </span>
                            <strong style={{ fontSize: "46px" }}>{t("main.bannerTopStrong")}</strong>
                            <span>{t("main.bannerTop2")}</span>
                        </div>
                        <div className="banner-txt-bottom-mb">{t("purchase.bannerBottom")}</div>
                        <div className="more">
                            <CircleArrowBtn onClick={handleScrollToSection} />
                        </div>
                    </div>
                </div>
                <div className="purchase-txt-mb" ref={purchaseTxtRef}>
                    <strong style={{ fontSize: "36px", color: "black" }}>
                    {t("purchase.purchaseTitle")}
                    </strong>
                    <div className="purchase-txt-bottom-mb">
                        <p>{t("purchase.content1")}</p>
                        <p>{t("purchase.content2")} </p>
                        <p>
                            <strong style={{ fontSize: "26px" }}>{t("purchase.content3Strong")}</strong> 
                            {t("purchase.content3")}
                        </p>
                        <p>
                        {t("purchase.content4")}
                        </p>
                        {t("purchase.content5")}
                    </div>
                </div>
            </Mobile>
        </div>
    );
}

export default Purchase;
