import React, { useRef } from 'react';
import { Mobile, PC } from './ReactiveLayout';
import CircleArrowBtn from '../components/MainHomePg/Main/CircleArrowBtn';
import M from '../images/manage/m.jpg'
import { useTranslation } from 'react-i18next';
function Manage(props) {
    const {t}=useTranslation()
          // ref를 생성하여 purchase-txt 구간을 참조
          const purchaseTxtRef = useRef(null);
          // 버튼 클릭 시 스크롤 이벤트 처리
          const handleScrollToSection = () => {
              if (purchaseTxtRef.current) {
                  purchaseTxtRef.current.scrollIntoView({ behavior: 'smooth' });
              }
          };
    return (
        <div>
            <PC>
            <div className="remodeling-area">
                {/* 맨위에 이미지랑 글자 */}
<div className="main-banner-txts">
        <div className="banner-txt-top">
       <div className="column"
       style={{
        display:"flex",
        flexDirection:"column"
       }}
       >
       <span>
    <strong>
        {t("manage.bannerTop1")}
    </strong>
       </span>
      
       </div>
        </div>
        <div className="banner-txt-bottom">
        {t("manage.bannerBottom")}
        </div>
        <div className="more">
                <CircleArrowBtn onClick={handleScrollToSection} />
        </div>
    </div>
    <div className="remodeling-img">
    <img src={M}
    style={{
        width:"800px"
    }}
    />

    </div>
</div>
<div className="staging-txt"
ref={purchaseTxtRef}
>
    <strong
    style={{
        fontSize:"40px",
        color:"black",
        textAlign:"center"
    }}
    >{t("manage.title")}</strong>
    <div className="staging-txt-bottom">
    {t("manage.content1")}
    </div>
    {/* <div className="more">
                <CircleArrowBtn />
        </div> */}
</div>
            </PC>
            <Mobile>
            <div className="remodeling-area-mb">
                {/* 맨위에 이미지랑 글자 */}
                <div className="remodeling-img-mb">
    <img src={M}
    style={{
        width:"800px"
    }}
    />

    </div>
<div className="main-banner-txts-mb">
        <div className="banner-txt-top-mb">
       
       <span>
        <strong>{t("manage.bannerTop1")}</strong>
        </span>
       
      
       </div>
        </div>
        <div className="banner-txt-bottom-mb"
         style={{
            width:"calc(100% - 100px)",
    
            display:"flex",
            flexDirection:"column"
           }}
        >
        {t("manage.bannerBottom")}
        </div>
        <div className="more"
         style={{
            width:"calc(100% - 80px)",
            display:"flex",
            alignItems:"flex-start"
        }}
        >
                <CircleArrowBtn onClick={handleScrollToSection}/>
        </div>
  
</div>
<div className="staging-txt-mb"
ref={purchaseTxtRef}
>
    <strong
    style={{
        fontSize:"40px",
        color:"black",
    }}
    >{t("manage.title")}</strong>
    <div className="staging-txt-bottom-mb">
    {t("manage.content1")}
    </div>
    <div className="more">
                <CircleArrowBtn />
        </div>
</div>

            </Mobile>
        </div>
    );
}

export default Manage;