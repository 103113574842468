import React, { useState } from 'react';
import { AppBar, Box, Button, IconButton, Toolbar, Popover, Typography } from '@mui/material';
import AppbarLogo from './mainpg-logo.svg';
import MenuBtn from './MenuBtn';
import TranslateToggle from './TranslateToggle';
import './MainCustomAppbar.css';
import DirectMenuBtn from './DirectMenuBtn';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function MainCustomAppbar(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const { t,i18n} = useTranslation();
    const navigate=useNavigate()

    const handleMenuClick = (event, menu) => {
      console.log(event.currentTarget)
      console.log(menu)
        setAnchorEl(event.currentTarget);
        setSelectedMenu(menu);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedMenu(null);
    };

    const open = Boolean(anchorEl);
    const menuBtnSx={
      display:"flex",
      alignItems:"flex-start",
      textAlign:"left",
      color:"black",
      fontSize:"16px",
    }
    const navigateMenu=(e)=>{
        navigate(e.target.id)
    }
const Menus=()=>{
  if (i18n.language==="en"?selectedMenu==="SALE":selectedMenu==="매물") {
   return <Popover
   sx={{
   
   }}
   open={open}
   anchorEl={anchorEl}
   onClose={handleClose}
   anchorOrigin={{
       vertical: 'bottom',
       horizontal: 'center',
   }}
   transformOrigin={{
       vertical: 'top',
       horizontal: 'center',
   }}
>
<Box sx={{ p: 2 ,
  display:"flex",
  flexDirection:"column"
}}>
<Button
id={"/purchase"}
sx={menuBtnSx}
onClick={navigateMenu}
>{t("appbar.sale1")}</Button>
<Button
id={"/sale"}
sx={menuBtnSx}
onClick={navigateMenu}
>{t("appbar.sale2")}</Button>
<Button
id={"/commercial"}
sx={menuBtnSx}
onClick={navigateMenu}
>{t("appbar.sale3")}</Button>
</Box>
</Popover>
   
  }
  else if(i18n.language==="en"?selectedMenu==="REMODELING":selectedMenu==="리모델링"){
   return <Popover
   sx={{
 
   }}
   open={open}
   anchorEl={anchorEl}
   onClose={handleClose}
   anchorOrigin={{
       vertical: 'bottom',
       horizontal: 'center',
   }}
   transformOrigin={{
       vertical: 'top',
       horizontal: 'center',
   }}
>
<Box sx={{ p: 2 ,
  display:"flex",
  flexDirection:"column"
}}>
<Button
id={"/remodel-res"}
sx={menuBtnSx}
onClick={navigateMenu}
>{t("appbar.remodeling1")}</Button>

<Button
id={"/remodel-purchase"}
sx={menuBtnSx}
onClick={navigateMenu}
>{t("appbar.remodeling2")}</Button>

</Box>
</Popover>

  }
return   
}
    return (
        <AppBar
            variant="outlined"
            position="static"
            sx={{ backgroundColor: "white", borderBottom: "1px solid #d9d9d9" }}
        >
            <Toolbar sx={{ display: "flex", justifyContent: "center" }}>
                <Box sx={{ width: "calc(100% - 20px - 20px)", display: 'flex', alignItems: 'center' }}>
                    <IconButton color="inherit"
                    onClick={()=>{
                        navigate("/")
                    }}
                    >
                        <img src={AppbarLogo} width={"76px"} alt="Main Logo" />
                    </IconButton>
                    <div style={{ flex: 1 }} />
                    <div className="menu-btns">
                        {[{title:t(`appbar.sale`),id:""}, {title:t(`appbar.remodeling`),id:""}].map((menu, index) => (
                            <MenuBtn 
                                key={index} 
                                title={menu.title} 
                                
                                onClick={(event) => handleMenuClick(event, menu.title)}
                            />
                        ))}
                        {[{title:t(`appbar.homestaging`),id:"/homestaging"}, {title:t(`appbar.flip`),id:"/flip"}, {title:t(`appbar.property`),id:"/manage"}, {title:t(`appbar.contact`),id:"/contact"}, {title:t(`appbar.listProperties`),id:"/list-properties"}].map((menu,idx)=>(<DirectMenuBtn title={menu.title} id={menu.id}/>))}
                    </div>
                    <div style={{ flex: 1 }} />
                    <TranslateToggle />
                </Box>
            </Toolbar>
        
              {Menus()}
        </AppBar>
    );
}

export default MainCustomAppbar;
