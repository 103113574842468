import React, { useRef } from 'react';
import { Mobile, PC } from './ReactiveLayout';
import './HomeStaging.css'
import HS from '../images/homestaging/homestaging.jpg'
import s1 from '../images/homestaging/s1.jpg'
import s2 from '../images/homestaging/s2.jpg'
import s3 from '../images/homestaging/s3.jpg'
import s4 from '../images/homestaging/s4.jpg'
import s5 from '../images/homestaging/s5.jpg'
import s6 from '../images/homestaging/s6.jpg'
import s7 from '../images/homestaging/s7.jpg'
import s8 from '../images/homestaging/s8.jpg'
import s9 from '../images/homestaging/s9.jpg'
import s10 from '../images/homestaging/s10.jpg'
import s11 from '../images/homestaging/s11.jpg'
import s12 from '../images/homestaging/s12.jpg'
import CircleArrowBtn from '../components/MainHomePg/Main/CircleArrowBtn';
import { useTranslation } from 'react-i18next';
function HomeStaging(props) {
     // ref를 생성하여 purchase-txt 구간을 참조
     const purchaseTxtRef = useRef(null);
const {t}=useTranslation()
     // 버튼 클릭 시 스크롤 이벤트 처리
     const handleScrollToSection = () => {
         if (purchaseTxtRef.current) {
             purchaseTxtRef.current.scrollIntoView({ behavior: 'smooth' });
         }
     };
    return (
        <div
        // className='main-pg'
        >
            <PC>
            <div className="remodeling-area">
                {/* 맨위에 이미지랑 글자 */}
<div className="main-banner-txts">
        <div className="banner-txt-top">
       <div className="column"
       style={{
        display:"flex",
        flexDirection:"column"
       }}
       >
       <span>
        <strong
        style={{
            fontSize:"50px"
        }}
        >{t("homeStaging.bannerTop1")}</strong></span>
       </div>
        </div>
        <div className="banner-txt-bottom">
       {t("homeStaging.bannerBottom")}
       
        </div>
        <div className="more">
                <CircleArrowBtn onClick={handleScrollToSection} />
        </div>
    </div>
    <div className="remodeling-img">
    <img src={HS}
    style={{
        width:"800px"
    }}
    />

    </div>
</div>
<div className="staging-txt" ref={purchaseTxtRef}>
    <strong
    style={{
        fontSize:"40px",
        color:"black"
    }}
    > {t("homeStaging.title")}</strong>
    <div className="staging-txt-bottom">
    <p>
        {t("homeStaging.content1")}
    </p>
<p>
{t("homeStaging.content2")}
</p>
{t("homeStaging.content3")}


    </div>
</div>
{/* 집 사진 그리드 */}
<div className="photos-grid">
    {/* 첫번째 줄 */}
<div className="photo-row">
    <div
    className='photo-grid-container'
    >
        <img 
        className='big-img'
style={{
    width:"900px"
}}
src={s1}/>
    </div>
    <div
    className='photo-grid-container md-photo'
    >
        <img 
style={{
    width:"620px"
}}
src={s2}/>
    </div>
</div>
{/* 두번째 줄 */}
<div className="photo-row">
<div
    className='photo-grid-container md-photo'
    >
        <img 
style={{
    width:"620px"
}}
src={s3}/>
    </div>
    <div
    className='photo-grid-container sm-photo'
    >
        <img 
        className='sm-img'
style={{
    width:"620px"
}}
src={s4}/>
    </div>
    <div
    className='photo-grid-container sm-photo'
    >
        <img 
        className='sm-img'
style={{
    width:"620px"
}}
src={s5}/>
    </div>
</div>
{/* 세번째 줄 */}
<div className="photo-row">
    <div
    className='photo-grid-container'
    >
        <img 
        className='big-img'
style={{
    width:"900px"
}}
src={s6}/>
    </div>
    <div
    className='photo-grid-container md-photo'
    >
        <img 
style={{
    width:"620px"
}}
src={s7}/>
    </div>
</div>
{/* 네번째 줄 */}
<div className="photo-row">

    <div
    className='photo-grid-container sm-photo'
    >
        <img 
        className='sm-img'
style={{
    width:"620px"
}}
src={s8}/>
    </div>
    <div
    className='photo-grid-container sm-photo'
    >
        <img 
        className='sm-img'
style={{
    width:"620px"
}}
src={s9}/>
    </div>
    <div
    className='photo-grid-container md-photo'
    >
        <img 
style={{
    width:"620px"
}}
src={s10}/>
    </div>
</div>
{/* 다섯번째 줄 */}
<div className="photo-row">
   
    <div
    className='photo-grid-container md-photo'
    >
        <img 
style={{
    width:"620px"
}}
src={s11}/>
    </div>
    <div
    className='photo-grid-container'
    >
        <img 
        className='big-img'
style={{
    width:"900px"
}}
src={s12}/>
    </div>
</div>
</div>
            </PC>
            <Mobile>
            <div className="remodeling-area-mb">
                {/* 맨위에 이미지랑 글자 */}
                <div className="remodeling-img-mb">
    <img src={HS}
    style={{
        // overflow:"hidden",
        width:"800px"
    }}
    />

    </div>
<div className="main-banner-txts-mb">
        <div className="banner-txt-top-mb">
      
      
       <span>
        <strong
        style={{
            fontSize:"36px"
        }}
        >{t("homeStaging.bannerTop1")}</strong></span>
        <span>
    
       </span>
       </div>
        <div className="banner-txt-bottom-mb">
        {t("homeStaging.bannerBottom")}
       
        </div>
        <div className="more">
                <CircleArrowBtn onClick={handleScrollToSection}/>
        </div>
    </div>
 
</div>
<div className="staging-txt-mb" ref={purchaseTxtRef}>
    <strong
    style={{
        fontSize:"40px",
        color:"black"
    }}
    >{t("homeStaging.title")}</strong>
    <div className="staging-txt-bottom-mb">
    <p>
    {t("homeStaging.content1")}</p>
<p>
{t("homeStaging.content2")}</p>

{t("homeStaging.content3")}

    </div>
</div>
{/* 집 사진 그리드 */}
<div className="photos-grid-mb">
    {/* 첫번째 줄 */}
    <div
    className='photo-grid-container-mb'
    >
        <img 
        className='mb-homestaging-img'
style={{
    // width:"900px"
}}
src={s1}/>
    <div
    className='photo-grid-container-mb'
    >
        <img 
    className='mb-homestaging-img'
src={s2}/>
    </div>
</div>
{/* 두번째 줄 */}
<div
    className='photo-grid-container-mb'
    >
        <img 
 className='mb-homestaging-img'
 style={{
    transform:"translateX(-260px)"
 }}
src={s3}/>
    <div
    className='photo-grid-container-mb'
    >
        <img 
   className='mb-homestaging-img'
style={{
    width:"620px"
}}
src={s4}/>
    </div>
    <div
    className='photo-grid-container'
    >
        <img 
        className='sm-img'
style={{
    width:"620px"
}}
src={s5}/>
    </div>
</div>
{/* 세번째 줄 */}
    <div
    className='photo-grid-container-mb'
    >
        <img 
          className='mb-homestaging-img'
// style={{
//     width:"900px"
// }}
src={s6}/>
    </div>
    <div
    className='photo-grid-container-mb'
    >
        <img 
    className='mb-homestaging-img'
src={s7}/>
    </div>
{/* 네번째 줄 */}

    <div
    className='photo-grid-container-mb'
    >
        <img 
       className='mb-homestaging-img'
src={s8}/>
    </div>
    <div
    className='photo-grid-container-mb'
    >
        <img 
          className='mb-homestaging-img'
src={s9}/>
    </div>
    <div
    className='photo-grid-container-mb'
    >
        <img 
  className='mb-homestaging-img'
src={s10}/>
</div>
{/* 다섯번째 줄 */}
   
    <div
    className='photo-grid-container-mb'
    >
        <img 
  className='mb-homestaging-img'
src={s11}/>
    </div>
    <div
    className='photo-grid-container-mb'
    >
        <img 
         className='mb-homestaging-img'
src={s12}/>
    </div>
</div>
            </Mobile>
        </div>
    );
}

export default HomeStaging;