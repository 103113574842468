import React from 'react';
import { Mobile, PC } from './ReactiveLayout';
import CircleArrowBtn from '../components/MainHomePg/Main/CircleArrowBtn';
import './Contact.css'
import BdProfileSrc from '../images/profile/bd-pf.png'
import { RiKakaoTalkFill } from "react-icons/ri";
import BDQR from '../images/qr-code/bd-file-qr.png'
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
function Contact(props) {
    const {t,i18n}=useTranslation()
    return (
        <div>
            <PC>
            <div className="contact-area">
                {/* 맨위에 이미지랑 글자 */}    
                <div className="profile-img">
    <img src={BdProfileSrc}
    style={{
     height:"100%"
    }}
    />

    </div>
<div className="contact-txts">
    <div className="contact-title">
        {t("contact.title")}
    </div>
    <div className="contact-title">
    {t("contact.content")}
    </div>
    <div className="contact-info">
        <strong
        className='info-title'
        >   {t("contact.name")}</strong>
        <span
        className='info-content'
        >{i18n.language==="en"?'Brady Bongdu Kim, Realtor, Real Estate Broker':'김봉두, Brady Bongdu Kim, Realtor, Real Estate Broker'}</span>
    </div>
    <div className="contact-info">
        <strong
        className='info-title'
        >{t("contact.license")}</strong>
        <span
        className='info-content'
        >DRE broker license</span>
           <span
        className='info-content'
        >General Contractor license</span>
    </div>
    <div className="contact-info">
        <strong
        className='info-title'
        >{t("contact.phone")}</strong>
        <span
        className='info-content'
        style={{
            fontSize:"26px",
            fontWeight:700
        }}
        >949-701-1910</span>
    </div>
 
    <div className="contact-info">
        <strong
        className='info-title'
        >{t("contact.email")}</strong>
        <span
        className='info-content'
        
        >Bradybongdukim@gmail.com</span>
    </div>
    <div className="contact-info">
        <strong
        className='info-title'
       
        >
            <span>{t("contact.kakaoId")} 
                <span
                 style={{
                    fontSize:"26px",
                    fontWeight:700,
                    color:"#4f3919"
                }}
                >(ocbrady)</span>
                </span>
            <RiKakaoTalkFill size={30}/></strong>
        {/* <a
     href='http://qr.kakao.com/talk/q4Fqv85q0K3zY1t6fHU4ZN8CCec-'
     className='info-content'
     target="_blank"               // 새 창에서 링크를 열도록 설정
     rel="noopener noreferrer"     // 보안 및 성능 문제를 방지
        >ocbrady</a> */}
         <QRCode
    style={{ height: "auto", maxWidth: "100px", width: "100%" }}
    value={'http://qr.kakao.com/talk/q4Fqv85q0K3zY1t6fHU4ZN8CCec-'}
  />
    </div>
    <div className="contact-info">
        <strong
        className='info-title'
        >{t("contact.website")}</strong>
        <a
        href='https://bdinvestmentcompany.com'
        className='info-content'
        >https://bdinvestmentcompany.com</a>
    </div>
    <div className="contact-info">
        <strong
        className='info-title'
        >{t("contact.naverCafe")}</strong>
        <a
        href='https://cafe.naver.com/bradyinvestment'
        className='info-content'
        >https://cafe.naver.com/bradyinvestment</a>
    </div>
</div>

</div>
<ul className="history-ul">
    <li className='history-li'>
    <span className='year'>2024 - </span>
    <span className='history-content'>{t("contact.hist1")}</span>

    </li>
    <li className='history-li'>
    <span className='year'>2023 - </span>
    <span className='history-content'>{t("contact.hist2")}
    </span>

    </li>
    <li className='history-li'>
    <span className='year'>2022 - </span>
    <span className='history-content'> {t("contact.hist3")}
    </span>

    </li>
    <li className='history-li'>
    <span className='year'>2021 - </span>
    <span className='history-content'> {t("contact.hist4")}

    </span>

    </li>
    <li className='history-li'>
    <span className='year'>2020 - </span>
    <span className='history-content'> {t("contact.hist4")}

    </span>

    </li>
    <li className='history-li'>
    <span className='year'>2019 - </span>
    <span className='history-content'> {t("contact.hist3")}


    </span>

    </li>
    <li className='history-li'>
    <span className='year'>2019 - </span>
    <span className='history-content'>{t("contact.hist5")}</span>

    </li>
    <li className='history-li'>
    <span className='year'>2018 - </span>
    <span className='history-content'>{t("contact.hist6")}
    </span>

    </li>
    <li className='history-li'>
    <span className='year'>2017 - </span>
    <span className='history-content'> {t("contact.hist3")}</span>

    </li>
</ul>
<div className="my-info">
{t("contact.hist7")}
</div>
            </PC>
            <Mobile>
            <div className="contact-area-mb">
                {/* 맨위에 이미지랑 글자 */}    
                <div className="profile-img-mb">
 <img
 style={{
    height:"100%"
 }}
 src={BdProfileSrc}
 />

    </div>
    <div className="contact-txts-mb">
    <div className="contact-title">
    {t("contact.title")}
    </div>
    <div className="contact-title">
    {t("contact.content")}
    </div>
    <div className="contact-info">
        <strong
        className='info-title'
        >{t("contact.name")}</strong>
        <span
        className='info-content'
        >김봉두</span>
    <span
        className='info-content'
        >Brady Bongdu Kim, Realtor, Real Estate Broker</span>
    
    </div>
    <div className="contact-info">
        <strong
        className='info-title'
        >{t("contact.license")}</strong>
        <span
        className='info-content'
        >BRE#(부동산 브로커 라이센스) 01994341</span>
           <span
        className='info-content'
        >CSLB#(건축 시공 라이센스) 1111927</span>
    </div>
    <div className="contact-info">
        <strong
        className='info-title'
        >{t("contact.phone")}</strong>
        <span
        className='info-content'
        style={{
            fontSize:"26px",
            fontWeight:700,
            color:"#4f3919"
        }}
        >949-701-1910</span>
    </div>
 
    <div className="contact-info">
        <strong
        className='info-title'
        >{t("contact.email")}</strong>
        <span
        className='info-content'
        >Bradybongdukim@gmail.com</span>
    </div>
    <div className="contact-info">
        <strong
        className='info-title'
        >
            <span>{t("contact.kakaoId")} </span>
            <RiKakaoTalkFill size={30}/></strong>
        <a
           href='http://qr.kakao.com/talk/q4Fqv85q0K3zY1t6fHU4ZN8CCec-'
           className='info-content'
           style={{
            fontSize:"26px",
            fontWeight:700,
            color:"#4f3919"
        }}
           target="_blank"               // 새 창에서 링크를 열도록 설정
           rel="noopener noreferrer"     // 보안 및 성능 문제를 방지
        >ocbrady</a>
    </div>
    <div className="contact-info">
        <strong
        className='info-title'
        >{t("contact.website")}</strong>
        <a
        href='https://bdinvestmentcompany.com'
        className='info-content'
        style={{
            fontSize:"18px",
             color:"#4f3919"
        }}
        >https://bdinvestmentcompany.com</a>
    </div>
    <div className="contact-info">
        <strong
        className='info-title'
        >{t("contact.naverCafe")}</strong>
        <a
        href='https://cafe.naver.com/bradyinvestment'
        className='info-content'
        style={{
            fontSize:"18px",
             color:"#4f3919"
        }}
        >https://cafe.naver.com/bradyinvestment</a>
    </div>
</div>

</div>
<ul className="history-ul-mb">
    <li className='history-li-mb'>
    <span className='year-mb'>2024</span>
    <span className='history-content'>{t("contact.hist1")}</span>

    </li>
    <li className='history-li-mb'>
    <span className='year-mb'>2023</span>
    <span className='history-content'>{t("contact.hist2")}
    </span>

    </li>
    <li className='history-li-mb'>
    <span className='year-mb'>2022</span>
    <span className='history-content'> {t("contact.hist3")}
    </span>

    </li>
    <li className='history-li-mb'>
    <span className='year-mb'>2021</span>
    <span className='history-content'> {t("contact.hist4")}

    </span>

    </li>
    <li className='history-li-mb'>
    <span className='year-mb'>2020</span>
    <span className='history-content'> {t("contact.hist4")}

    </span>

    </li>
    <li className='history-li-mb'>
    <span className='year-mb'>2019</span>
    <span className='history-content'> {t("contact.hist3")}


    </span>

    </li>
    <li className='history-li-mb'>
    <span className='year-mb'>2019</span>
    <span className='history-content'>{t("contact.hist5")}</span>

    </li>
    <li className='history-li-mb'>
    <span className='year-mb'>2018</span>
    <span className='history-content'>{t("contact.hist6")}
    </span>

    </li>
    <li className='history-li-mb'>
    <span className='year-mb'>2017</span>
    <span className='history-content'> {t("contact.hist3")}</span>

    </li>
</ul>
<div className="my-info-mb">
{t("contact.hist7")}
</div>
            </Mobile>
        </div>
    );
}

export default Contact;