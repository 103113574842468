import React, { useState, useEffect } from 'react';
import { Mobile, PC } from './ReactiveLayout';
import './ListProperties.css';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Card,
    CardContent,
    Typography,
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Input,
    FormHelperText,
    CardMedia,
    CircularProgress,
    Alert,
} from '@mui/material';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { db, storage } from '../service/firebase';
import { addDoc, collection, getDocs, updateDoc, doc, orderBy, query, where, deleteDoc } from 'firebase/firestore';

function ListProperties() {
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [authOpen, setAuthOpen] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [formData, setFormData] = useState({
        address: '',
        listPrice: '',
        bed: '',
        bath: '',
        sqft: '',
        lotSize: '',
        level: '',
        garage: '',
        yearBuilt: '',
        type: '',
        hoa: '',
        contactPhone: '',
        contactEmail: '',
        description: '',
    });
    const [editData, setEditData] = useState(null);
    const [properties, setProperties] = useState([]);
    const [images, setImages] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState('');
    const [authData, setAuthData] = useState({ id: '', pw: '' });

    const handleAuthChange = (e) => {
        const { name, value } = e.target;
        setAuthData({ ...authData, [name]: value });
    };

    const handleAuthSubmit = async () => {
        try {
            const userInfosRef = collection(db, 'userInfos');
            const q = query(userInfosRef, where("id", "==", authData.id), where("pw", "==", authData.pw));
            const querySnapshot = await getDocs(q);
            console.log("querySnapshot.docs[0].data()",querySnapshot.docs[0].data())
            if (!querySnapshot.empty) {
                if (querySnapshot.docs[0].data().userType==="admin") {
                    setIsAuthenticated(true);
                    setAuthOpen(false);
                    setOpen(true)
                }else{
                    alert("Invalid ID or Password.");
                }
             
            } else {
                alert("Invalid ID or Password.");
            }
        } catch (error) {
            alert("Invalid ID or Password.");
            console.error("Authentication error: ", error);
        }
    };

    const handleClickOpen = () => {
        if (!isAuthenticated) {
            setAuthOpen(true); // 인증되지 않은 경우 인증 다이얼로그 열기
        } else {
            setOpen(true); // 인증된 경우 등록 다이얼로그 열기
        }
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({
            address: '',
            listPrice: '',
            bed: '',
            bath: '',
            sqft: '',
            lotSize: '',
            level: '',
            garage: '',
            yearBuilt: '',
            type: '',
            hoa: '',
            contactPhone: '',
            contactEmail: '',
            description: '',
        });
        setImages([]);
    };

    const handleEditClickOpen = (property) => {
        if (!isAuthenticated) {
            setAuthOpen(true); // 인증되지 않은 경우 인증 다이얼로그 열기
        } else {
            setEditData(property);
            setEditOpen(true);
            setFormData({
                address: property.address,
                listPrice: property.listPrice,
                bed: property.bed,
                bath: property.bath,
                sqft: property.sqft,
                lotSize: property.lotSize,
                level: property.level,
                garage: property.garage,
                yearBuilt: property.yearBuilt,
                type: property.type,
                hoa: property.hoa,
                contactPhone: property.contactPhone,
                contactEmail: property.contactEmail,
                description: property.description,
            });
            const existingImages = property.images.map((imageUrl) => ({
                preview: imageUrl,
                file: null
            }));
            setImages(existingImages);
        }
    };
// 추가된 handleDelete 함수
const handleDelete = async (propertyId) => {
    if(!isAuthenticated){
        setAuthOpen(true); // 인증되지 않은 경우 인증 다이얼로그 열기
    }else{if (window.confirm("정말로 이 매물을 삭제하시겠습니까?")) {
        try {
            const docRef = doc(db, 'properties', propertyId);
            await deleteDoc(docRef);
            alert("매물이 삭제되었습니다.");
            fetchProperties(); // 삭제 후 리스트 갱신
        } catch (error) {
            console.error("매물 삭제 중 오류 발생: ", error);
            alert("삭제 중 문제가 발생했습니다. 다시 시도해 주세요.");
        }
    }}
};

    const handleEditClose = () => {
        setEditOpen(false);
        setFormData({
            address: '',
            listPrice: '',
            bed: '',
            bath: '',
            sqft: '',
            lotSize: '',
            level: '',
            garage: '',
            yearBuilt: '',
            type: '',
            hoa: '',
            contactPhone: '',
            contactEmail: '',
            description: '',
        });
        setImages([]);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        if (files.length + images.length <= 50) {
            const imagePreviews = files.map((file) => ({
                file,
                preview: URL.createObjectURL(file)
            }));
            setImages((prevImages) => [...prevImages, ...imagePreviews]);
        } else {
            alert("You can only upload a maximum of 50 images.");
        }
    };

    const removeImage = (index) => {
        setImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });
    };

    const handleSubmit = async () => {
        for (const field in formData) {
            if (formData[field] === '') {
                setError("Please fill out all required fields.");
                return;
            }
        }

        setIsUploading(true);
        setError('');

        try {
            const uploadPromises = images.map(async (image) => {
                const imageRef = ref(storage, `properties/${Date.now()}_${image.file.name}`);
                await uploadBytes(imageRef, image.file);
                return await getDownloadURL(imageRef);
            });

            const imageUrls = await Promise.all(uploadPromises);

            await addDoc(collection(db, 'properties'), {
                ...formData,
                images: imageUrls,
                timestamp: new Date()
            });

            alert("Property successfully registered.");
            setOpen(false);
            handleClose();
            fetchProperties();
        } catch (error) {
            console.error("Error adding document: ", error);
            setError("Error registering property. Please try again.");
        }

        setIsUploading(false);
    };

    const handleEditSubmit = async () => {
        if (!editData) return;
        const docRef = doc(db, 'properties', editData.id);

        try {
            const newUploadImages = images.filter(image => image.file !== null);

            const uploadPromises = newUploadImages.map(async (image) => {
                const imageRef = ref(storage, `properties/${Date.now()}_${image.file.name}`);
                await uploadBytes(imageRef, image.file);
                return await getDownloadURL(imageRef);
            });

            const newImageUrls = await Promise.all(uploadPromises);
            const updatedImageUrls = [
                ...images.filter(image => image.file === null).map(image => image.preview),
                ...newImageUrls,
            ];

            await updateDoc(docRef, {
                ...formData,
                images: updatedImageUrls
            });

            alert("매물 정보가 성공적으로 업데이트되었습니다.");
            handleEditClose();
            setEditData(null);
            fetchProperties();
        } catch (error) {
            console.error("문서를 업데이트하는 중 오류가 발생했습니다: ", error);
            setError("매물 정보를 업데이트하는 중 문제가 발생했습니다. 다시 시도해 주세요.");
        }
    };

    const fetchProperties = async () => {
        try {
            const propertiesRef = collection(db, "properties");
            const propertiesQuery = query(propertiesRef, orderBy("timestamp", "desc"));
            const querySnapshot = await getDocs(propertiesQuery);

            const propertiesList = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            setProperties(propertiesList);
        } catch (error) {
            console.error("Error fetching properties: ", error);
        }
    };

    useEffect(() => {
        fetchProperties();
    }, []);


    return (
        <div className='list-properties-container'>
        
            <Button variant="contained" color="primary" onClick={handleClickOpen} sx={{ marginBottom: '20px' }}>
                매물 등록
            </Button>
         
          {/* 인증 다이얼로그 */}
          <Dialog open={authOpen} onClose={() => setAuthOpen(false)}>
                <DialogTitle>Admin Authentication</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Admin ID"
                        name="id"
                        value={authData.id}
                        onChange={handleAuthChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Password"
                        name="pw"
                        type="password"
                        value={authData.pw}
                        onChange={handleAuthChange}
                        fullWidth
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAuthOpen(false)}>Cancel</Button>
                    <Button onClick={handleAuthSubmit}>Authenticate</Button>
                </DialogActions>
            </Dialog>
            
            <Dialog open={open} onClose={handleClose} >
                <DialogTitle>Register a Property</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please fill out the form below to register a new property.
                    </DialogContentText>
                    {error && <Alert severity="error">{error}</Alert>}
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="List Price"
                        name="listPrice"
                        value={formData.listPrice}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Bed"
                        name="bed"
                        type="number"
                        value={formData.bed}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Bath"
                        name="bath"
                        type="number"
                        value={formData.bath}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Sqft"
                        name="sqft"
                        type="number"
                        value={formData.sqft}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Lot Size"
                        name="lotSize"
                        value={formData.lotSize}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Level"
                        name="level"
                        value={formData.level}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Garage"
                        name="garage"
                        value={formData.garage}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Year Built"
                        name="yearBuilt"
                        type="number"
                        value={formData.yearBuilt}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Type"
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="HOA"
                        name="hoa"
                        value={formData.hoa}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Contact Phone"
                        name="contactPhone"
                        value={formData.contactPhone}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Contact Email"
                        name="contactEmail"
                        value={formData.contactEmail}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                    />
                    
                    <Input
                        accept="image/*"
                        id="image-upload"
                        type="file"
                        multiple
                        onChange={handleImageUpload}
                        sx={{ marginTop: '20px' }}
                    />
                    <FormHelperText>Upload up to 50 images.</FormHelperText>
                    <Typography variant="body2" color="textSecondary">
                        {images.length} / 50 images uploaded
                    </Typography>
                    
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                        {images.map((image, index) => (
                            <Grid item xs={4} key={index}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        height="100"
                                        image={image.preview}
                                        alt={`Image ${index + 1}`}
                                    />
                                    <Button 
                                        size="small" 
                                        color="secondary" 
                                        onClick={() => removeImage(index)}
                                    >
                                        Remove
                                    </Button>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary" disabled={isUploading}>
                        {isUploading ? <CircularProgress size={24} /> : "Submit"}
                    </Button>
                </DialogActions>
            </Dialog>

              {/* 수정 다이얼로그 */}
              <Dialog open={editOpen} onClose={handleEditClose} maxWidth="sm" fullWidth>
                <DialogTitle>Edit Property</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Update the property details below.
                    </DialogContentText>
                    {error && <Alert severity="error">{error}</Alert>}
                    <TextField
                        margin="dense"
                        label="Address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="List Price"
                        name="listPrice"
                        value={formData.listPrice}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Bed"
                        name="bed"
                        type="number"
                        value={formData.bed}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Bath"
                        name="bath"
                        type="number"
                        value={formData.bath}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Sqft"
                        name="sqft"
                        type="number"
                        value={formData.sqft}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Lot Size"
                        name="lotSize"
                        value={formData.lotSize}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Level"
                        name="level"
                        value={formData.level}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Garage"
                        name="garage"
                        value={formData.garage}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Year Built"
                        name="yearBuilt"
                        type="number"
                        value={formData.yearBuilt}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Type"
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="HOA"
                        name="hoa"
                        value={formData.hoa}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Contact Phone"
                        name="contactPhone"
                        value={formData.contactPhone}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Contact Email"
                        name="contactEmail"
                        value={formData.contactEmail}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                    />
                    <TextField
                        margin="dense"
                        label="Description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                    />
                     <Input
                        accept="image/*"
                        id="image-upload"
                        type="file"
                        multiple
                        onChange={handleImageUpload}
                        sx={{ marginTop: '20px' }}
                    />
                    <FormHelperText>Upload up to 50 images.</FormHelperText>
                    <Typography variant="body2" color="textSecondary">{images.length} / 50 images uploaded</Typography>
                    <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                        {images.map((image, index) => (
                            <Grid item xs={4} key={index}>
                                <Card>
                                    <CardMedia component="img" height="100" image={image.preview || image} alt={`Image ${index + 1}`} />
                                    <Button size="small" color="secondary" onClick={() => removeImage(index)}>Remove</Button>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleEditSubmit} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>


            <PC>
                <TableContainer 
                variant='outlined'
                sx={{
                    width:"1600px"
                }}
                component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Address</TableCell>
                                <TableCell>List Price</TableCell>
                                <TableCell>Bed</TableCell>
                                <TableCell>Bath</TableCell>
                                <TableCell>Sqft</TableCell>
                                <TableCell>Lot Size</TableCell>
                                <TableCell>Level</TableCell>
                                <TableCell>Garage</TableCell>
                                <TableCell>Year Built</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>HOA</TableCell>
                                <TableCell>Contact Info</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Edit</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {properties.map((property) => (
                                <TableRow key={property.id}>
                                    <TableCell>{property.address}</TableCell>
                                    <TableCell>{property.listPrice}</TableCell>
                                    <TableCell>{property.bed}</TableCell>
                                    <TableCell>{property.bath}</TableCell>
                                    <TableCell>{property.sqft}</TableCell>
                                    <TableCell>{property.lotSize}</TableCell>
                                    <TableCell>{property.level}</TableCell>
                                    <TableCell>{property.garage}</TableCell>
                                    <TableCell>{property.yearBuilt}</TableCell>
                                    <TableCell>{property.type}</TableCell>
                                    <TableCell>{property.hoa}</TableCell>
                                    <TableCell>
                                        <Typography>Phone: {property.contactPhone}</Typography>
                                        <Typography>Email: {property.contactEmail}</Typography>
                                    </TableCell>
                                    <TableCell>{property.description}</TableCell>
                                    <TableCell>
                                        <Button variant="outlined" onClick={() => handleEditClickOpen(property)}>
                                            Edit
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="outlined" onClick={() => handleDelete(property.id)}>
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </PC>

            <Mobile>
                <Grid container spacing={2}
               
                >
                    {properties.map((property) => (
                        <Grid item xs={12} key={property.id}
                        sx={{
                            width:"100%"
                        }}
                        >
                            <Card
                         variant='outlined'
                         sx={{
                                width:"calc(100% - 20px)",
                                marginLeft:"10px",
                                marginRight:"10px"
                            }}
                            >
                                <CardContent>
                                    <Typography variant="h6">{property.address}</Typography>
                                    <Typography><strong>List Price:</strong> {property.listPrice}</Typography>
                                    <Typography><strong>Bed:</strong> {property.bed}</Typography>
                                    <Typography><strong>Bath:</strong> {property.bath}</Typography>
                                    <Typography><strong>Sqft:</strong> {property.sqft}</Typography>
                                    <Typography><strong>Lot Size:</strong> {property.lotSize}</Typography>
                                    <Typography><strong>Level:</strong> {property.level}</Typography>
                                    <Typography><strong>Garage:</strong> {property.garage}</Typography>
                                    <Typography><strong>Year Built:</strong> {property.yearBuilt}</Typography>
                                    <Typography><strong>Type:</strong> {property.type}</Typography>
                                    <Typography><strong>HOA:</strong> {property.hoa}</Typography>
                                    <Typography><strong>Contact Info:</strong> {property.contactPhone}, {property.contactEmail}</Typography>
                                    <Typography><strong>Description:</strong> {property.description}</Typography>
                                    <Button variant="outlined" onClick={() => handleEditClickOpen(property)}>Edit</Button>
                                    <Button variant="outlined" 
                                    
                                    onClick={() =>  handleDelete(property.id)}>Delte</Button>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Mobile>
        </div>
    );
}

export default ListProperties;
