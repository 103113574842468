import React, { useEffect, useState } from 'react';
import { Mobile, PC } from './ReactiveLayout';
import HomeImg from '../images/home.jpeg'
import MainCustomAppbar from '../components/MainHomePg/AppBar/MainCustomAppbar';
import './MainPage.css'
import CircleArrowBtn from '../components/MainHomePg/Main/CircleArrowBtn';
import { Button, Card, CardContent, IconButton, Typography } from '@mui/material';
import H1 from '../images/house/h1.jpg'
import H2 from '../images/house/h2.jpg'
import H3 from '../images/house/h3.png'
import H4 from '../images/house/h4.jpg'
import c1 from '../images/commercial/c1.webp'
import c2 from '../images/commercial/c2.webp'
import c3 from '../images/commercial/c3.jpg'
import c4 from '../images/commercial/c4.webp'
import c5 from '../images/commercial/c5.webp'
import c6 from '../images/commercial/c6.webp'
import c7 from '../images/commercial/c7.jpg'
import c8 from '../images/commercial/c8.webp'
import gh1 from '../images/gridhouse/gh1.jpg'
import gh2 from '../images/gridhouse/gh2.jpeg'
import gh3 from '../images/gridhouse/gh3.jpeg'
import gh4 from '../images/gridhouse/gh4.jpeg'
import gh5 from '../images/gridhouse/gh5.jpeg'
import gh6 from '../images/gridhouse/gh6.jpeg'
import gh7 from '../images/gridhouse/gh7.jpeg'
import gh8 from '../images/gridhouse/gh8.jpeg'
import RemodelingImg from '../images/remodeling/remodeling.jpg'
import Before1 from '../images/remodeling/before1.jpg'
import Before2 from '../images/remodeling/before2.jpg'
import After1 from '../images/remodeling/after1.jpg'
import After2 from '../images/remodeling/after2.jpg'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaPen } from 'react-icons/fa6';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../service/firebase';
function MainPage(props) {
    const {t} = useTranslation();
   const navigate=useNavigate()
   const [moneyExpander,setMoneyExpander]=useState(false)
   const [properties, setProperties] = useState([]);
    // 이미지 프리로드 함수
    const preloadImages = (imagePaths) => {
        imagePaths.forEach((src) => {
            const img = new Image();
            img.src = src;
        });
    };

    // 페이지가 로드될 때 프리로드 실행
    useEffect(() => {
        const imagePaths = [
            HomeImg, H1, H2, H3, H4,
            c1, c2, c3, c4, c5, c6, c7, c8,
            gh1, gh2, gh3, gh4, gh5, gh6, gh7, gh8,
            RemodelingImg, Before1, Before2, After1, After2
        ];
        preloadImages(imagePaths);
    }, []); // 빈 배열을 주어 첫 렌더링에만 실행되도록 설정
   const onClickMoneyExpander=()=>{

    setMoneyExpander(p=>!p)
   }
   const SampleHouseCardSx = {
    minHeight:"550px",
        width: "335px",
        minWidth: "335px",
        display: "flex",
        flexDirection: "column",
        margin: 0,
        backgroundColor: "#F5E9C7"
    }
    const SampleHouseCardMbSx = {
        
        width: "300px",
        minWidth: "300px",
          margin: 0,
   
        display: "flex",
        flexDirection: "column",
      
        backgroundColor: "#F5E9C7"
    }
    const BeforeCardSx = {
        borderRadius: "30px",
        height: "300px",
        width: "260px"
    }
    const BeforeAfterCardMbSx = {
        borderRadius: "30px",
        height: "300px",
        minWidth:"300px",
        width: "100%"
    }
    const AfterCardSx = {
        borderRadius: "30px",
        height: "300px",
        width: "100%"
    }
    const SampleHouseCardContentSx = {
        padding: 0
    }
    const GridCardMbSx={
        borderRadius:"8px",
        width:"100%",
        minWidth:"280px",
        overflow:"hidden",
        padding:0,
        margin:0,
        height:"360px"
    }
    const fetchProperties = async () => {
        try {
            const propertiesRef = collection(db, "properties");
            const propertiesQuery = query(propertiesRef, orderBy("timestamp", "desc")); // timestamp 기준 내림차순 정렬
            const querySnapshot = await getDocs(propertiesQuery);
    
            const propertiesList = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
    
            setProperties(propertiesList.slice(0,4));
        } catch (error) {
            console.error("Error fetching properties: ", error);
        }
    };
    useEffect(() => {
        fetchProperties();
    }, []);
    return (
        <div

        >
            <PC>
                {/* 메인 배너 */}
                <div className="main-banner">
                    {/* left txt area */}
                    <div className="main-banner-txts">
                        <div className="banner-txt-top">
                        {t('main.bannerTop1')}
                            <strong
                                style={{
                                    fontSize: "53px"
                                }}
                            > {t('main.bannerTopStrong')}</strong>

                        {t('main.bannerTop2')}
                            
                        </div>
                        <div className="banner-txt-bottom">
                        {t('main.bannerBottom')}

                        </div>
                        <div className="more">
                            <CircleArrowBtn onClick={()=>{
                                navigate("/purchase")
                            }}/>
                        </div>
                    </div>
                    <div className="home-img">
                        <img src={HomeImg} />
                    </div>
                </div>
                {/* 샘플 집 구간 */}
                <div className="house-cards">
                <div className='show-all'>
                        <CircleArrowBtn onClick={()=>{
                            navigate("/list-properties")
                        }} title='showAll'/>

                    </div>
                    <ul>
                        {/* 샘플 집 1 */}
                       {properties.map(p=><li>

<Card variant='elevation'
    sx={SampleHouseCardSx}
>
    <CardContent
        sx={SampleHouseCardContentSx}
    >
        <div className="house-img-container"   style={{
                    // width:"100%",
                    backgroundColor:"#d9d9d9"
                }}>

            <img
                className='sample-house-img'
              
                src={p.images[0]||''}
            />
        </div>

        <div className="sample-house-info">
            <strong
                className='sample-house-title'
            >{p.listPrice}</strong>
            <span className='sample-house-contents'>
                {p.bed} beds {p.bath} baths
            </span>
            <span className='sample-house-contents'>
                {p.address}
            </span>
          
            <div className="sample-more">
                <CircleArrowBtn />
            </div>

        </div>
    </CardContent>
</Card>
</li>) }
                       
                    </ul>
                    


                </div>
                {/* 재정적 지원 */}
                <div className="money-area">
                    <strong className="money-title"> {t('moneyArea.moneyTitle')}</strong>
                    <div className="money-info">{t('moneyArea.moneyInfo')}</div>
                    <div className="money-more">
                        <CircleArrowBtn onClick={onClickMoneyExpander}/>

                    </div>
{moneyExpander&&
<div
className='money-more-area-wrapper'
><div
className='money-more-area'
>
<div className="money-more-info">{t("moneyMore.info1")}</div>
<div className="money-more-info">{t("moneyMore.info2")}</div>
<div className="money-more-info"> {t("moneyMore.info3")}</div>
<div className="money-more-info"> {t("moneyMore.info4")} </div>
<div className="money-more-info">{t("moneyMore.info5")}</div>
<div className="money-more-info">{t("moneyMore.info6")} </div>
<div className="money-more-info">{t("moneyMore.info7")}</div>
</div></div>

}
                </div>
                {/* 리모델링 */}
                <div className="remodeling-area">
                    <div className="main-banner-txts">
                        <div className="banner-txt-top">
                            <div className="column"
                                style={{
                                    display: "flex",
                                    flexDirection: "column"
                                }}
                            >
                                <span>{t("remodelingArea.bannerTop1")}</span>
                                <span>
                                    <strong
                                        style={{
                                            fontSize: "50px"
                                        }}
                                    >{t("remodelingArea.bannerTopStrong")}</strong>
                                    {t("remodelingArea.bannerTop2")}</span>
                            </div>
                        </div>
                        <div className="banner-txt-bottom">
                        {t("remodelingArea.bannerBottom")}
                        </div>
                        <div className="more">
                            <CircleArrowBtn  onClick={()=>{navigate("/remodel-res")}}/>
                        </div>
                    </div>
                    <div className="remodeling-img">
                        <img src={RemodelingImg} />

                    </div>
                </div>
                <div className="remodeling-before-after">
                    {/* 첫번째줄 사진 */}
                    <div className="remodeling-row">

                        {/* 전 */}
                        <div className="before-area">
                            <Card
                                sx={BeforeCardSx}
                            >
                                <CardContent
                                    sx={SampleHouseCardContentSx}
                                >
                                    <img
                                        className='remodeling-before-img'
                                        src={Before1} />
                                </CardContent>
                            </Card>
                            <span>{t("imgGrid.before")}</span>
                        </div>
                        {/* 후 */}
                        <div className="after-area">
                            <Card
                                sx={AfterCardSx}
                            >
                                <CardContent
                                    sx={SampleHouseCardContentSx}
                                >
                                    <img
                                        className='remodeling-after-img'
                                        src={After1} />
                                </CardContent>
                            </Card>
                            <span>{t("imgGrid.after")}</span>
                        </div>
                    </div>
                    {/* 두번째줄 사진 */}
                    <div className="remodeling-row">

                        {/* 전 */}
                        <div className="before-area">
                            <Card
                                sx={BeforeCardSx}
                            >
                                <CardContent
                                    sx={SampleHouseCardContentSx}
                                >
                                    <img
                                        className='remodeling-before-img'
                                        src={Before2} />
                                </CardContent>
                            </Card>
                            <span>{t("imgGrid.before")}</span>
                        </div>
                        {/* 후 */}
                        <div className="after-area">
                            <Card
                                sx={AfterCardSx}
                            >
                                <CardContent
                                    sx={SampleHouseCardContentSx}
                                >
                                    <img
                                        className='remodeling-after-img'
                                        src={After2} />
                                </CardContent>
                            </Card>
                            <span>{t("imgGrid.after")}</span>
                        </div>
                    </div>
                    {/* 프로로서 */}
                    <div className="pro">
                        <div className="pro-row">{t("pro.pro1")}</div>
                        <div className="pro-row">{t("pro.pro2")}</div>
                    </div>
                    {/* 공사 사진 그리드 */}
                    <div className="photos-grid">
                        {/* 첫번째 줄 */}
                        <div className="photo-row">
                            <div
                                className='photo-grid-container'
                            >
                                <img
                                    className='big-img'
                                    style={{
                                        width: "900px"
                                    }}
                                    src={c1} />
                            </div>
                            <div
                                className='photo-grid-container md-photo'
                            >
                                <img
                                    className='md-img'
                                    style={{
                                        width: "620px"
                                    }}
                                    src={c2} />
                            </div>
                        </div>
                        {/* 두번째 줄 */}
                        <div className="photo-row">
                            <div
                                className='photo-grid-container md-photo'
                            >
                                <img
                                    className='md-img'
                                    style={{
                                        width: "620px"
                                    }}
                                    src={c3} />
                            </div>
                            <div
                                className='photo-grid-container sm-photo'
                            >
                                <img
                                    className='sm-img'
                                    style={{
                                        width: "620px"
                                    }}
                                    src={c4} />
                            </div>
                            <div
                                className='photo-grid-container sm-photo'
                            >
                                <img
                                    className='sm-img'
                                    style={{
                                        width: "620px"
                                    }}
                                    src={c5} />
                            </div>
                        </div>
                        {/* 세번째 줄 */}
                        <div className="photo-row">

                            <div
                                className='photo-grid-container sm-photo'
                            >
                                <img
                                    className='sm-img'
                                    style={{
                                        width: "620px"
                                    }}
                                    src={c6} />
                            </div>
                            <div
                                className='photo-grid-container sm-photo'
                            >
                                <img
                                    className='sm-img'
                                    style={{
                                        width: "620px"
                                    }}
                                    src={c7} />
                            </div>
                            <div
                                className='photo-grid-container md-photo'
                            >
                                <img
                                    className='md-img'
                                    style={{
                                        width: "800px"
                                    }}
                                    src={c8} />
                            </div>
                        </div>
                    </div>
                    {/* 완벽한 집 문구 */}
                    <div className="complete-house">
                        <div className="complete-txt">
                           {t("completeHouse.house")}
                        </div>
                        <div className="complete-txt txt-indent">
                        {t("completeHouse.pro")}
                        </div>
                    </div>
                    {/* 집 사진 그리드 */}
                    <div className="photos-grid">
                        {/* 첫번째 줄 */}
                        <div className="photo-row">
                            <div
                                className='photo-grid-container'
                            >
                                <img
                                    className='big-img'
                                    style={{
                                        width: "900px"
                                    }}
                                    src={gh1} />
                            </div>
                            <div
                                className='photo-grid-container md-photo'
                            >
                                <img
                                    className='md-img'
                                    style={{
                                        transform: "translateY(0)",
                                        width: "620px"
                                    }}
                                    src={gh2} />
                            </div>
                        </div>
                        {/* 두번째 줄 */}
                        <div className="photo-row">
                            <div
                                className='photo-grid-container md-photo'
                            >
                                <img
                                    className='md-img'
                                    style={{
                                        transform: "translateY(0)",
                                        width: "620px"
                                    }}
                                    src={gh3} />
                            </div>
                            <div
                                className='photo-grid-container sm-photo'
                            >
                                <img
                                    className='sm-img'
                                    style={{
                                        transform: "translateX(-160px)",
                                        width: "600px"
                                    }}
                                    src={gh4} />
                            </div>
                            <div
                                className='photo-grid-container sm-photo'
                            >
                                <img
                                    className='sm-img'
                                    style={{
                                        transform: "translateX(-120px)",
                                        width: "700px"
                                    }}
                                    src={gh5} />
                            </div>
                        </div>
                        {/* 세번째 줄 */}
                        <div className="photo-row">

                            <div
                                className='photo-grid-container sm-photo'
                            >
                                <img
                                    className='sm-img'
                                    style={{
                                        transform: "translateX(-120px)",
                                        width: "600px"
                                    }}
                                    src={gh6} />
                            </div>
                            <div
                                className='photo-grid-container sm-photo'
                            >
                                <img
                                    className='sm-img'
                                    style={{
                                        transform: "translateY(0px)",
                                        transform: "translateX(-400px)",
                                        height: "100%"
                                    }}
                                    src={gh7} />
                            </div>
                            <div
                                className='photo-grid-container md-photo'
                            >
                                <img
                                    className='md-img'
                                    style={{
                                        transform: "translateY(0px)",
                                        width: "620px"
                                    }}
                                    src={gh8} />
                            </div>
                        </div>
                    </div>
                </div>
            </PC>
            <Mobile>
                 {/* 메인 배너 */}
                 <div className="main-banner-mb">
                 <div className="home-img-mb">
                        <img src={HomeImg} />

                    </div>
                    {/* left txt area */}
                    <div className="main-banner-txts-mb">
                        <div className="banner-txt-top-mb">
                            <span>{t('main.bannerTop1')}</span>
                            
                            <strong
                                style={{
                                    fontSize: "46px"
                                }}
                            > {t('main.bannerTopStrong')}</strong>
                        {t('main.bannerTop2')}
                        </div>
                        <div className="banner-txt-bottom-mb">
                        {t('main.bannerBottom')}
                        </div>
                        <div className="more">
                            <CircleArrowBtn onClick={()=>{
                                navigate("/purchase")
                            }}/>
                        </div>
                    </div>
                  

                </div>
                {/* 샘플 집 구간 */}
                <div className="house-cards-mb">
                <div className='show-all'>
                        <CircleArrowBtn onClick={()=>{
                            navigate("/list-properties")
                        }} title='showAll'/>

                    </div>
                    <ul>
                        {/* 샘플 집 1 */}
                       {properties.map(p=> <li>

<Card variant='elevation'
    sx={SampleHouseCardMbSx}
>
    <CardContent
        sx={SampleHouseCardContentSx}
    >
         <div className="house-img-container"   style={{
                    // width:"100%",
                    backgroundColor:"#d9d9d9"
                }}>

            <img
                className='sample-house-img'
              
                src={p.images[0]||''}
            />
        </div>

        <div className="sample-house-info">
            <strong
                className='sample-house-title'
            >{p.listPrice}</strong>
            <span className='sample-house-contents'>
                {p.bed} beds {p.bath} baths
            </span>
            <span className='sample-house-contents'>
                {p.address}
            </span>
          
            <div className="sample-more">
                <CircleArrowBtn />
            </div>

        </div>
    </CardContent>
</Card>
</li>)}
                       
                    </ul>


                </div>
                 {/* 재정적 지원 */}
                 <div className="money-area-mb">
                    <strong className="money-title">{t('moneyArea.moneyTitle')}</strong>
                    <div className="money-info-mb">{t('moneyArea.moneyInfo')}</div>
                    <div className="money-more">
                        <CircleArrowBtn onClick={onClickMoneyExpander}/>

                    </div>
                    {moneyExpander&&
<div
className='money-more-area-mb'
>
    <p>{t("moneyMore.info1")}</p>
    {t("moneyMore.info2")}
<p>{t("moneyMore.info3")}</p>
{t("moneyMore.info4")}
  <p>{t("moneyMore.info5")}</p>
  {t("moneyMore.info6")}
  {t("moneyMore.info7")}
</div>

}
                </div>
                   {/* 리모델링 */}
                   <div className="remodeling-area-mb">
                   <div className="remodeling-img-mb">
                        <img src={RemodelingImg} />

                    </div>
                    <div className="main-banner-txts-mb">
                        <div className="banner-txt-top-mb">
                           
                                <span>{t("remodelingArea.bannerTop1")}</span>
                                <span>
                                    <strong
                                        style={{
                                            fontSize: "50px"
                                        }}
                                    >{t("remodelingArea.bannerTopStrong")}</strong>
                                   
                                     </span> 
                                     <span>{t("remodelingArea.bannerTop2")}</span>
                        </div>
                        <div className="banner-txt-bottom-mb">
                        {t("remodelingArea.bannerBottom")}
                        </div>
                        <div className="more">
                            <CircleArrowBtn onClick={()=>{navigate("/remodel-res")}}/>
                        </div>
                    </div>
                  
                </div>
                {/* 비포애프터 */}
                <div className="remodeling-before-after-mb">
                    {/* 첫번째줄 사진 */}

                        {/* 전 */}
                        <div className="before-area-mb">
                            <Card
                                sx={BeforeAfterCardMbSx}
                            >
                                <CardContent
                                    sx={SampleHouseCardContentSx}
                                >
                                    <img
                                        className='remodeling-before-img'
                                        src={Before1} />
                                </CardContent>
                            </Card>
                            <span>{t("imgGrid.before")}</span>
                        </div>
                        {/* 후 */}
                        <div className="before-area-mb">
                            <Card
                                sx={BeforeAfterCardMbSx}
                            >
                                <CardContent
                                    sx={SampleHouseCardContentSx}
                                >
                                    <img
                                        className='remodeling-after-img'
                                        src={After1} />
                                </CardContent>
                            </Card>
                            <span>{t("imgGrid.after")}</span>
                        </div>
                    {/* 두번째줄 사진 */}

                        {/* 전 */}
                        <div className="before-area-mb">
                            <Card
                                sx={BeforeAfterCardMbSx}
                            >
                                <CardContent
                                    sx={SampleHouseCardContentSx}
                                >
                                    <img
                                        className='remodeling-before-img'
                                        src={Before2} />
                                </CardContent>
                            </Card>
                            <span>{t("imgGrid.before")}</span>
                        </div>
                        {/* 후 */}
                        <div className="before-area-mb">
                            <Card
                                sx={BeforeAfterCardMbSx}
                            >
                                <CardContent
                                    sx={SampleHouseCardContentSx}
                                >
                                    <img
                                        className='remodeling-after-img'
                                        src={After2} />
                                </CardContent>
                            </Card>
                            <span>{t("imgGrid.after")}</span>
                        </div>
                    {/* 프로로서 */}
                    <div className="pro-mb">
                        <div className="pro-row-mb">{t("pro.pro1")}</div>
                        <div className="pro-row-mb">{t("pro.pro2")}</div>
                    </div>
                    {/* 공사 사진 그리드 */}
                    <div className="photos-grid-mb">
                        {/* 첫번째 줄 */}
                        <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > <img
                                    className='pro-img-mb'
                                    style={{
                                    }}
                                    src={c1} />
                                    </CardContent></Card>
                               
                               <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > 
                                <img
                                    className='pro-img-mb'
                                  style={{
                                    height:"450px",
                                    transform:"translateY(-50px)"
                                  }}
                                    src={c2} />
                                </CardContent>
                            </Card>
                            <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > 
                                <img
                                    className='pro-img-mb'
                                    style={{
                                      height:"530px",
                                      transform:"translateY(-110px)",
                                    }}
                                    src={c3} />
                                </CardContent>
                            </Card>
                            <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > 
                                <img
                                    className='pro-img-mb'
                                    style={{
                                    //   height:"530px",
                                    //   transform:"translateY(-110px)",
                                    }}
                                    src={c4} />
                                </CardContent>
                            </Card>
                            <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > 
                                <img
                                    className='pro-img-mb'
                                    // style={{
                                    //   height:"530px",
                                    //   transform:"translateY(-110px)",
                                    // }}
                                    src={c5} />
                                </CardContent>
                            </Card>
                            <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > 
                                <img
                                    className='pro-img-mb'
                                    // style={{
                                    //   height:"530px",
                                    //   transform:"translateY(-110px)",
                                    // }}
                                    src={c6} />
                                </CardContent>
                            </Card>
                            <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > 
                                <img
                                    className='pro-img-mb'
                                    // style={{
                                    //   height:"530px",
                                    //   transform:"translateY(-110px)",
                                    // }}
                                    src={c7} />
                                </CardContent>
                            </Card>
                            <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > 
                                <img
                                    className='pro-img-mb'
                                    // style={{
                                    //   height:"530px",
                                    //   transform:"translateY(-110px)",
                                    // }}
                                    src={c8} />
                                </CardContent>
                            </Card>
                     
                    </div>
                    {/* 완벽한 집 문구 */}
                    <div className="complete-house-mb">
                    <div className="complete-txt-mb">
                          {t("completeHouse.house")}
                        </div> 
                        
                        <div className="complete-txt-mb">
                        {t("completeHouse.pro")}
                        </div>
                    </div>
                    {/* 집 사진 그리드 */}
                    <div className="photos-grid-mb">
                        {/* 첫번째 줄 */}
                        <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > <img
                                    className='pro-img-mb'
                                    style={{
                                    }}
                                    src={gh1} />
                                    </CardContent></Card>
                               
                               <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > 
                                <img
                                    className='pro-img-mb'
                                //   style={{
                                //     height:"450px",
                                //     transform:"translateY(-50px)"
                                //   }}
                                    src={gh2} />
                                </CardContent>
                            </Card>
                            <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > 
                                <img
                                    className='pro-img-mb'
                                    // style={{
                                    //   height:"530px",
                                    //   transform:"translateY(-110px)",
                                    // }}
                                    src={gh3} />
                                </CardContent>
                            </Card>
                            <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > 
                                <img
                                    className='pro-img-mb'
                                    style={{
                                    //   height:"530px",
                                    //   transform:"translateY(-110px)",
                                    }}
                                    src={gh4} />
                                </CardContent>
                            </Card>
                            <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > 
                                <img
                                    className='pro-img-mb'
                                    // style={{
                                    //   height:"530px",
                                    //   transform:"translateY(-110px)",
                                    // }}
                                    src={gh5} />
                                </CardContent>
                            </Card>
                            <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > 
                                <img
                                    className='pro-img-mb'
                                    // style={{
                                    //   height:"530px",
                                    //   transform:"translateY(-110px)",
                                    // }}
                                    src={gh6} />
                                </CardContent>
                            </Card>
                            <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > 
                                <img
                                    className='pro-img-mb'
                                    style={{
                                      height:"360px",
                                      transform:"translateX(-260px)",
                                    }}
                                    src={gh7} />
                                </CardContent>
                            </Card>
                            <Card
                        sx={GridCardMbSx}
                        >
                        <CardContent
                        sx={SampleHouseCardContentSx}
                        > 
                                <img
                                    className='pro-img-mb'
                                    // style={{
                                    //   height:"530px",
                                    //   transform:"translateY(-110px)",
                                    // }}
                                    src={gh8} />
                                </CardContent>
                            </Card>
                     
                    </div>
                </div>
                 
            </Mobile>
        </div>
    );
}

export default MainPage;